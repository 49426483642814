import image01 from "../assets/jpg/hobbies/hiking.jpg";
import image02 from "../assets/jpg/hobbies/climbing.jpg";
import image03 from "../assets/jpg/hobbies/playing.jpg";

export const hobby01 = {
  title: "Hiking",
  image: image01,
};

export const hobby02 = {
  title: "Climbing",
  image: image02,
};

export const hobby03 = {
  title: "Playing",
  image: image03,
};
